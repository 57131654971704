<template lang="pug">
  .row
    .col-md-6
      .component-card
        label.mr-4 Default
        v-chip.ma-2(
          v-for="(item, index) in options"
          :key="index") {{ item.title }}
      .component-card
        label.mr-4 Colored
        v-chip.ma-2(
          colored
          :color="item.color"
          v-for="(item, index) in options"
          :key="index") {{ item.title }}
      .component-card
        label.mr-4 Outline
        v-chip.ma-2(
          outline
          v-for="(item, index) in options"
          :key="index") {{ item.title }}
      .component-card
        label.mr-4 Colored
        v-chip.ma-2(
          colored
          outline
          v-for="(item, index) in options"
          :key="index"
          :color="item.color") {{ item.title }}
    .col-md-6
      .component-card
        label.mr-4 VChipGroup
        v-chip-group(
          :options="options"
          v-model="selected")
      .component-card
        label.mr-4 VChipGroup
        v-chip-group(
          color="#9C27B0"
          :options="options"
          v-model="selected")
</template>

<script>
export default {
  name: 'VSelectDemo',

  data: () => ({
    options: [
      {
        title: 'Red',
        color: '#f44336'
      },
      {
        title: 'Green',
        color: '#4CAF50'
      },
      {
        title: 'Purple',
        color: '#9C27B0'
      },
      {
        title: 'Blue',
        color: '#3F51B5'
      }
    ],
    selected: []
  })
}
</script>

<style>
</style>
